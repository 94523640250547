import React, { useEffect, useState } from 'react';
import { Grid, GridRow } from '@naf/grid';
import { useDispatch } from 'react-redux';
import useSelector from '../../../../redux/typedHooks';
import { actions as guides } from '../../../../redux/modules/consumerGuides';
import { useAuth0Token } from '../../../../hooks/useAuth0Token';
import { ArticleLoader } from '../../../skeleton-loaders/ArticleLoader';
import { ConsumerTestCard } from '../../../../pages/ConsumerTestGuide/Components/ConsumerTestCard/ConsumerTestCard';
import * as S from './Styles';

export const ConsumerGuideBlock = () => {
  const { simpleToken } = useAuth0Token();
  const dispatch = useDispatch();
  const { items } = useSelector((state) => state.consumerGuides.data);
  const isUpdating = useSelector((state) => state.consumerGuides.isUpdatingGuide);
  const [initialRequestMade, setInitialRequestMade] = useState(false);
  const [tokenRequestMade, setTokenRequestMade] = useState(false);
  const placeholderList = [...Array(6).keys()];

  useEffect(() => {
    if (!initialRequestMade) {
      dispatch(guides.getConsumerGuides.request({ token: undefined }));
      setInitialRequestMade(true);
    }
  }, [dispatch, initialRequestMade]);

  useEffect(() => {
    if (simpleToken && !tokenRequestMade) {
      dispatch(guides.getConsumerGuides.request({ token: simpleToken }));
      setTokenRequestMade(true);
    }
  }, [dispatch, simpleToken, tokenRequestMade]);

  if (isUpdating && (!items || items.length === 0)) {
    return (
      <Grid>
        <GridRow>
          {placeholderList.map((i) => (
            <S.CardGridWrapper s="12" m="12" l="4" xl="4" key={`consumerTestCard-placeholder-item-${i}`}>
              <ArticleLoader />
            </S.CardGridWrapper>
          ))}
        </GridRow>
      </Grid>
    );
  }

  return <GridRow>{items && items.map((item) => <ConsumerTestCard key={item.slug} test={item} />)}</GridRow>;
};
