import styled from 'styled-components';
import { GridCol } from '@naf/grid';
import { breakpoints } from '@naf/theme';

export const CardGridWrapper = styled(GridCol)`
  margin-bottom: 30px;

  @media (max-width: ${breakpoints.m}) {
    margin-bottom: 0;
  }
`;
