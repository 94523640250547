import React from 'react';
import { CardGrid } from '@naf/card-grid';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { AdvancedImage, lazyload } from '@cloudinary/react';
import { ConsumerGuideDetail } from '../../../../../../types/consumerTestType';
import { useCloudinary } from '../../../../hooks/Cloudinary/useCloudinary';
import { StyledLink } from '../../../../components/styled-link/StyledLink';
import defaultImage from '../../../../../assets/images/defaultLocalArticle.svg?url';
import * as S from './Styles';

export const ConsumerTestCard = ({ test }: { test: ConsumerGuideDetail }) => {
  const { slug, image, title, ingress, paywallDetails } = test;
  const link = `/forbrukertester/${slug}`;
  const imageAlt = image?.altText;
  const cld = useCloudinary();
  const itemImage =
    (image &&
      image.publicId &&
      cld.image(image.publicId).resize(fill().width(768).height(432)).quality('auto:eco').format('auto')) ||
    undefined;

  const localImage = defaultImage;

  return (
    <S.CardGridWrapper s="12" m="12" l="4" xl="4" key={`consumertest-item-${title}`}>
      <StyledLink to={link}>
        <CardGrid
          headerTag="h2"
          title={title}
          img={{
            src: localImage,
            alt: '',
          }}
          advancedImage={
            itemImage && (
              <AdvancedImage
                alt={imageAlt || ''}
                cldImg={itemImage}
                plugins={[lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 })]}
              />
            )
          }
          date=" "
          memberLabel={
            paywallDetails
              ? {
                  labelText: paywallDetails?.labelText || 'For medlemmer',
                  inverted: true,
                  isEligible: paywallDetails?.isAccessibleForUser,
                }
              : undefined
          }
        >
          {ingress}
        </CardGrid>
      </StyledLink>
    </S.CardGridWrapper>
  );
};
